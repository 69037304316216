import * as React from 'react'
import { Link } from 'gatsby'
import { createToken } from '@/functions/createToken'

interface Props {
    numPages: number
    currentPage: number
    basePath: string
    className?: string
}

export const Pagination: React.FC<Props> = ({
    numPages,
    currentPage,
    basePath,
    className,
}) => {
    return (
        <div className={`c-pagination ${className || ''}`}>
            {Array.from({ length: numPages }).map((_, i) => (
                <React.Fragment key={createToken()}>
                    {i + 1 === currentPage ? (
                        <span className="c-pagination__btn is-current">
                            {i + 1}
                        </span>
                    ) : (
                        <Link
                            to={`${basePath}/${i + 1 === 1 ? '' : `${i + 1}`}`}
                            className="c-pagination__btn"
                        >
                            {i + 1}
                        </Link>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}
