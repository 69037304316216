export const createToken = (): string => {
    const str: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const tokenLength: number = 16;
    let token: string = '';

    [...Array(tokenLength)].map(() => {
        token += str[Math.floor(Math.random() * str.length)]
    }).join('');

    return token;
}