import * as React from "react"

import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { PaginationPageContext } from "@/types/PageContext"
import { Pagination } from "@/components/Pagination"
import { PageProps } from "gatsby"
import { useImage } from "@/hooks/useImage"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { PageGoodsQuery } from "@root/types/graphql-types"
import { GoodsCard } from "@/components/Cards/GoodsCards"

type Props = PageProps<PageGoodsQuery, PaginationPageContext>

export const Goods: React.FC<Props> = ({ data, pageContext }) => {
    const { getImageProps, Image } = useImage()

    return (
        <GlobalLayout Head={<Head title="goods" />}>
            <SectionWrap
                Title={
                    <TitleWithBgImg
                        title="GOODS INFO"
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <div className={"p-section__content"}>
                    <div className="p-menus">
                        {data.allContentfulGoods?.edges ? (
                            data.allContentfulGoods.edges.map(({ node }) => (
                                <article className="p-menus__content">
                                    <GoodsCard
                                        title={node.goodsTitle || ""}
                                        href={`/goods/${node.slug}`}
                                        Image={
                                            <Image
                                                {...getImageProps(
                                                    node.goodsIcon
                                                        ?.gatsbyImageData
                                                )}
                                            />
                                        }
                                        price={
                                            node.goodsPrice
                                                ? Number(node.goodsPrice)
                                                : "unknown"
                                        }
                                        isConcertExclusive={
                                            node.goodsUrl !== undefined
                                        }
                                        className="u-width-100"
                                    />
                                </article>
                            ))
                        ) : (
                            <p>グッズが登録されていません</p>
                        )}
                    </div>
                    <Pagination
                        currentPage={pageContext.currentPage}
                        numPages={pageContext.numberOfPages}
                        basePath="/goods"
                        className="p-section__pagination"
                    />
                </div>
            </SectionWrap>
        </GlobalLayout>
    )
}

export default Goods
