import * as React from 'react'

import { SectionType } from '@/types/SectionType'

export const SectionWrap: React.FC<SectionType> = ({
    addClass,
    children,
    Title,
    bgColor,
}) => {
    return (
        <section
            className={`p-section${addClass || ''}`}
            style={{ background: bgColor || 'white' }}
        >
            {Title}
            {children}
        </section>
    )
}
