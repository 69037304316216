import { Goods } from '@/components/pages/Goods'
import { graphql } from 'gatsby'

export default Goods

export const query = graphql`
    query PageGoods($skip: Int!, $limit: Int!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        allContentfulGoods(
            sort: { fields: updatedAt, order: DESC }
            skip: $skip
            limit: $limit
            filter: { slug: { ne: "dummy" } }
        ) {
            edges {
                node {
                    ...GoodsInformation
                }
            }
        }
    }
`
