import * as React from 'react'

type Props = {
    addClass?: string
    title: string
    BgImage?: React.ReactElement
}

export const TitleWithBgImg: React.FC<Props> = ({
    addClass,
    title,
    BgImage,
}) => {
    return (
        <>
            {BgImage ? (
                <>
                    <div className={`c-title-bg${addClass || ''}`}>
                        <div className="c-title-bg__img">{BgImage}</div>
                        <div className="c-title-bg__wrap" />
                        <h2 className="c-title-bg__title">{title}</h2>
                    </div>
                    <div className="c-title-bg__dammy" />
                </>
            ) : (
                <h2 className={`p-section__title${addClass || ''}`}>{title}</h2>
            )}
        </>
    )
}
