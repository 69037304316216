export const formatDate = (d: Date | string, format?: '-' | '/'): string => {
    if (typeof d === 'string') {
        d = new Date(d)
    }

    if (d.toString() === 'Invalid Date') {
        console.error('Invalid Argument Exception：' + d)
        return ''
    }

    const year: string = String(d.getFullYear())
    const month: string = ('00' + String(d.getMonth() + 1)).slice(-2)
    const date: string = ('00' + String(d.getDate())).slice(-2)

    const f = format || ''
    return year + f + month + f + date
}
